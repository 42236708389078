import { useParams } from "react-router-dom";
import { faker } from "@faker-js/faker";

import { useState, useRef, useContext, useEffect } from "react";
import NeoKeContext from "context";
import { httpsCallable } from "firebase/functions";
import { functions, firestore } from "../../util/firebase";
const { dbError } = firestore;
const { add } = firestore.doc;
const { getAccountByEmail } = firestore.helpers;
import { Form, Input, Button, Select } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { t } from "i18next";
import { EyeTwoTone, EyeInvisibleOutlined } from "@ant-design/icons";

function ReserveForm() {
  const { hotels, account } = useContext(NeoKeContext);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const formRef = useRef();

  const { hotelId, checkInDate, checkOutDate } = useParams();
  const hotel = hotels.find((hotel) => hotel.id == hotelId);
  const dispatcher = httpsCallable(functions, "dispatcher");

  const filterPreferences = (preferences) =>
    preferences
      ? Object.keys(preferences).filter((k) => preferences[k] === "true")
      : [];

  const readablePreferences = (preferences) =>
    Object.keys(preferences).map(
      (k) =>
        preferences[k].charAt(0).toUpperCase() +
        preferences[k].slice(1).replaceAll("_", " "),
    );

  useEffect(() => {
    if (account && Object.keys(account).length) {
      formRef.current.setFieldsValue({
        first_name: account.firstName,
        last_name: account.lastName,
        email: account.email,
        phone: account.phone
          ? `+${account.phone.country} ${account.phone.number}`
          : "",
        accessibility_needs: filterPreferences(account.accessibility_needs),
        dietary_preferences: filterPreferences(account.dietary_preferences),
        flight_preferences: filterPreferences(account.flight_preferences),
      });
    }
  }, [account]);

  const onFinish = (values, docRef) => {
    setLoading(true);

    const email = values.email.trim().toLowerCase();
    if ((!account || !Object.keys(account).length) && !docRef) {
      getAccountByEmail(email, (snapshot) => {
        if (!snapshot.empty) {
          onFinish(values, snapshot.docs[0].id);
        } else {
          add("account", { email, notifications: false })
            .then(({ id }) => {
              onFinish(values, id);
            })
            .catch((error) => {
              dbError(error);
              setLoading(false);
            });
        }
      });
    } else {
      const cities = hotel.city.split(";");
      const hours = hotel.address.split(";");
      let result = { ...values };
      delete result.accessibility_needs;
      delete result.dietary_preferences;
      delete result.flight_preferences;
      delete result.card_cvc;
      delete result.card_expiration_date;
      delete result.card_number;
      delete result.card_name;
      result.check_in_date = checkInDate.replaceAll("_", "/");
      result.check_out_date = checkOutDate.replaceAll("_", "/");
      result.account = docRef ?? account.docRef;
      result.hotel_id = hotelId;
      result.hotel_owner_id = hotel.account;
      result.phone = values.phone;
      result.isGuess = docRef !== undefined;
      result.flight = {
        number: hotel.name,
        cities: {
          from: `${cities[0]} (${cities[1]})`,
          to: `${cities[2]} (${cities[3]})`,
        },
        dates: {
          departure: `${result.check_in_date} ${hours[0]}`,
          arrival: `${result.check_in_date} ${hours[1]}`,
        },
        preferences: {
          "Flight preferences": readablePreferences(
            values.flight_preferences ?? [],
          ),
          "Dietary preferences": readablePreferences(
            values.dietary_preferences ?? [],
          ),
          "Accessibility needs": readablePreferences(
            values.accessibility_needs ?? [],
          ),
        },
      };
      if (hotel.speedy_check_in) {
        if (undefined == result.speedy_check_in) {
          result.speedy_check_in = true;
        }
      }

      result.dispatch = "reserveRoom";
      dispatcher(result)
        .then(() => {
          setLoading(false);
          window.location = `/reservation/${email}/${cities[2]}`;
        })
        .catch((error) => {
          dbError(error);
          setLoading(false);
        });
    }
  };

  const SelectWithWrapper = (props) => (
    <div className="ant-select-multiple-wrapper">
      <Select {...props} />
    </div>
  );

  const renderForm = () => {
    return (
      <>
        <Form
          {...{
            layout: "vertical",
          }}
          ref={formRef}
          name="reserve"
          onFinish={onFinish}
          initialValues={{
            card_name: "Thanos Samarinas",
            card_number: "XXXX-XXXX-XXXX-XXXX",
            card_expiration_date: "MM/YY",
            card_cvc: faker.finance.creditCardCVV(),
          }}
        >
          <div
            style={{ display: page == 0 ? "flex" : "none" }}
            className="form-header"
          >
            <div>
              <ArrowLeftOutlined onClick={() => window.history.back()} />
            </div>
            <h3>Passenger info</h3>
            <div></div>
          </div>
          <Form.Item
            style={{ display: page == 0 ? "block" : "none" }}
            name="first_name"
            label="First name (as it appears on the passport)"
            rules={[
              {
                required: true,
                message: t("form.generic.field.validation.first_name"),
              },
            ]}
          >
            <Input
              disabled={account.firstName && account.firstName != ""}
              placeholder={t("form.generic.field.placeholder.first_name")}
            />
          </Form.Item>
          <Form.Item
            style={{ display: page == 0 ? "block" : "none" }}
            name="last_name"
            label="Last name (as it appears on the passport)"
            rules={[
              {
                required: true,
                message: t("form.generic.field.validation.last_name"),
              },
            ]}
          >
            <Input
              disabled={account.lastName && account.lastName != ""}
              placeholder={t("form.generic.field.placeholder.last_name")}
            />
          </Form.Item>
          <div
            style={{ display: page == 0 ? "flex" : "none" }}
            className="form-header"
          >
            <div></div>
            <h3>Contant information</h3>
            <div></div>
          </div>
          <Form.Item
            style={{ display: page == 0 ? "block" : "none" }}
            name="email"
            label="Email address"
            rules={[
              {
                required: true,
                type: "email",
                message: t("form.generic.field.validation.email"),
              },
            ]}
          >
            <Input
              disabled={account.email != undefined}
              placeholder={t("form.generic.field.placeholder.email")}
            />
          </Form.Item>
          <Form.Item
            style={{ display: page == 0 ? "block" : "none" }}
            name="phone"
            label="Phone number"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Input
              disabled={account.phone != undefined}
              placeholder="Enter your phone number"
            />
          </Form.Item>
          <div
            style={{ display: page == 1 ? "flex" : "none" }}
            className="form-header"
          >
            <div>
              <ArrowLeftOutlined onClick={() => setPage(page - 1)} />
            </div>
            <h3>Preferences</h3>
            <div></div>
          </div>
          <Form.Item
            style={{ display: page == 1 ? "block" : "none" }}
            name="flight_preferences"
            label="In-flight seat preferences"
          >
            <SelectWithWrapper
              mode="tags"
              placeholder="Select your in-flight seat preferences"
              options={["window", "aisle", "extra_leg_room", "front_seats"].map(
                (o) => ({
                  value: o,
                  label:
                    o.charAt(0).toUpperCase() + o.slice(1).replaceAll("_", " "),
                }),
              )}
            />
          </Form.Item>
          <Form.Item
            style={{ display: page == 1 ? "block" : "none" }}
            name="dietary_preferences"
            label="Dietary preferences"
          >
            <SelectWithWrapper
              mode="tags"
              placeholder="Select your dietary preferences"
              options={["dairy", "eggs", "fish", "gluten", "honey", "meat"].map(
                (o) => ({
                  value: o,
                  label:
                    o.charAt(0).toUpperCase() + o.slice(1).replaceAll("_", " "),
                }),
              )}
            />
          </Form.Item>
          <Form.Item
            style={{ display: page == 1 ? "block" : "none" }}
            name="accessibility_needs"
            label="Accessibility needs"
          >
            <SelectWithWrapper
              mode="tags"
              placeholder="Select your accessibility needs"
              options={[
                "trouble_walking",
                "visually_impaired",
                "hard_of_hearing",
                "learning_impaired",
                "wheelchair_access",
              ].map((o) => ({
                value: o,
                label:
                  o.charAt(0).toUpperCase() + o.slice(1).replaceAll("_", " "),
              }))}
            />
          </Form.Item>
          <div
            style={{ display: page == 2 ? "flex" : "none" }}
            className="form-header"
          >
            <div>
              <ArrowLeftOutlined onClick={() => setPage(page - 1)} />
            </div>
            <h3>Payment info</h3>
            <div></div>
          </div>
          <Form.Item
            style={{ display: page == 2 ? "block" : "none" }}
            name="card_name"
            label="Name"
          >
            <Input placeholder="Enter the card owner" />
          </Form.Item>
          <Form.Item
            style={{ display: page == 2 ? "block" : "none" }}
            name="card_number"
            label="Card number"
          >
            <Input placeholder="Enter the card number" />
          </Form.Item>
          <div className="form-row">
            <Form.Item
              style={{ display: page == 2 ? "block" : "none" }}
              name="card_expiration_date"
              label="Expiration date"
            >
              <Input placeholder="Enter the card expiration date" />
            </Form.Item>
            <Form.Item
              style={{ display: page == 2 ? "block" : "none" }}
              name="card_cvc"
              label="CVC"
            >
              <Input.Password
                placeholder="Enter your CVC"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </Form.Item>
          </div>
          <Form.Item style={{ display: page == 0 ? "block" : "none" }}>
            <Button
              id="form-button"
              className="form-buttom"
              type="primary"
              onClick={() => setPage(page + 1)}
              block
            >
              Continue
            </Button>
          </Form.Item>
          <Form.Item style={{ display: page == 1 ? "block" : "none" }}>
            <Button
              id="form-button"
              className="form-buttom"
              type="primary"
              onClick={() => setPage(page + 1)}
              block
            >
              Continue
            </Button>
          </Form.Item>
          <Form.Item style={{ display: page == 2 ? "block" : "none" }}>
            <Button
              id="form-button"
              className="form-buttom"
              type="primary"
              shape="round"
              htmlType="submit"
              loading={loading}
              block
            >
              Finalise your booking
            </Button>
          </Form.Item>
        </Form>
      </>
    );
  };

  return renderForm();
}

export default ReserveForm;
