import { Row, Col, Button } from "antd";
import { isMobile } from "react-device-detect";
import plane from "../../images/plane.svg";

const HotelDataPanel = ({
  className,
  hotel,
  brief,
  checkInDate,
  checkOutDate,
  reserve,
  hideButton,
}) => {
  let location = undefined;
  if (!hideButton) {
    if (reserve) {
      location = `/reserve/${hotel.id}/${checkInDate}/${checkOutDate}`;
    } else if (brief) {
      location = `/hotel/${hotel.id}`;
    }
  }

  const getContent = () => {
    return (
      <>
        <div className="hotel-data">
          <div className="hotel-data-image">
            <img src={hotel.image} />
          </div>
          <div className="hotel-data-description">
            <div className="flight-details">
              <div className="flight-content">
                <div className="left">
                  <div className="destination">{hotel.city.split(";")[1]}</div>
                  <div className="hour">{hotel.address.split(";")[0]}</div>
                </div>
                <div className="plane">
                  <img src={plane} />
                </div>
                <div className="right">
                  <div className="destination">{hotel.city.split(";")[3]}</div>
                  <div className="hour">{hotel.address.split(";")[1]}</div>
                </div>
              </div>
              <div className="flight-duration">
                <div className="hotel-address">
                  {hotel.slogan}
                  <span>Direct</span>
                </div>
              </div>
            </div>
          </div>
          <div className="hotel-data-buttons">
            {location && (
              <Button
                className="see-hotel-details-button"
                type="primary"
                shape="round"
                size="small"
                onClick={() => (window.location = location)}
              >
                € {hotel.price}
              </Button>
            )}
          </div>
        </div>
        {!brief && (
          <Row className="hotel-extended-info">
            <Col>
              <div className="hotel-slogan">{hotel.slogan}</div>
              <div className="hotel-description">{hotel.description}</div>
            </Col>
          </Row>
        )}
      </>
    );
  };

  return (
    <div className={`${brief ? "hotel" : `hotel panel ${className}`}`}>
      {location && isMobile ? (
        <a href={location}>{getContent()}</a>
      ) : (
        <>{getContent()}</>
      )}
    </div>
  );
};

export default HotelDataPanel;
