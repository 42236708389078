import SearchPanel from "components/panels/SearchPanel";
import { Row } from "antd";

const Search = () => {
  return (
    <div className="container search-container ant-col-24">
      <Row justify="space-evenly" align="top">
        <SearchPanel />
      </Row>
    </div>
  );
};

export default Search;
