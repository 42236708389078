import ReservePanel from "components/panels/ReservePanel";
import { Row } from "antd";

const Reserve = () => {
  return (
    <div className="container reserve-container ant-col-24">
      <Row justify="space-evenly" align="top">
        <ReservePanel />
      </Row>
    </div>
  );
};

export default Reserve;
