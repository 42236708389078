import { useRef, useContext, useState } from "react";
import NeoKeContext from "context";
import moment from "moment";
import { Form, Button, Select, DatePicker } from "antd";
const { Option } = Select;
import { t } from "i18next";

function SearchForm() {
  const { hotels } = useContext(NeoKeContext);
  const formRef = useRef();

  const [selectedToCity, setSelectedToCity] = useState(null);
  const [availableToCities, setAvailableToCities] = useState(new Set());

  let fromCities = new Set();
  let airports = new Map();
  let travels = new Map();
  hotels.forEach((hotel) => {
    const [fromCity, fromAirport, toCity, toAirport] = hotel.city.split(";");
    fromCities.add(fromCity);
    airports.set(fromCity, fromAirport);
    airports.set(toCity, toAirport);
    const travelsFromCity = travels.get(fromCity) || new Set();
    travelsFromCity.add(toCity);
    travels.set(fromCity, travelsFromCity);
  });

  const handleFromCityChange = (value) => {
    const newAvailableToCities = travels.get(value) || new Set();
    setAvailableToCities(newAvailableToCities);
    if (!newAvailableToCities.has(selectedToCity)) {
      formRef.current.setFieldsValue({ toCity: null });
      setSelectedToCity(null);
    }
  };

  const onFinish = (values) => {
    let check_in_date = values.dates.format("YYYY_MM_DD");
    let check_out_date = values.dates.format("YYYY_MM_DD");
    const travel = `${values.fromCity};${airports.get(values.fromCity)};${
      values.toCity
    };${airports.get(values.toCity)}`;
    window.location = `/search-result/${travel}/${check_in_date}/${check_out_date}`;
  };

  const disabledDate = (current) => {
    return current < moment().startOf("day");
  };

  const renderForm = () => {
    return (
      <Form
        {...{
          layout: "vertical",
        }}
        ref={formRef}
        name="reserve"
        onFinish={onFinish}
      >
        <Form.Item
          name="fromCity"
          label="From"
          rules={[
            {
              required: true,
              message: t("form.generic.field.validation.destination"),
            },
          ]}
        >
          <Select
            placeholder="Select origin"
            allowClear
            onChange={handleFromCityChange}
          >
            {Array.from(fromCities).map((city) => (
              <Option key={city} value={city}>
                {city}
              </Option>
            ))}
            ;
          </Select>
        </Form.Item>
        <Form.Item
          name="toCity"
          label="To"
          rules={[
            {
              required: true,
              message: t("form.generic.field.validation.destination"),
            },
          ]}
        >
          <Select placeholder="Select destination" allowClear>
            {Array.from(availableToCities).map((city) => (
              <Option key={city} value={city}>
                {city}
              </Option>
            ))}
            ;
          </Select>
        </Form.Item>
        <Form.Item
          name="dates"
          label="Date"
          rules={[
            {
              required: true,
              message: t("form.generic.field.validation.dates"),
            },
          ]}
        >
          <DatePicker disabledDate={disabledDate} />
        </Form.Item>
        <Form.Item {...{}}>
          <Button
            id="form-button"
            className="form-buttom"
            type="primary"
            shape="round"
            htmlType="submit"
            block
          >
            Search flights
          </Button>
        </Form.Item>
      </Form>
    );
  };

  return renderForm();
}

export default SearchForm;
