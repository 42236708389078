import { Row } from "antd";
import { useParams } from "react-router-dom";
import { CheckCircleOutlined } from "@ant-design/icons";

function ReservationForGuess() {
  const { guess, to } = useParams();
  return (
    <div className="container reservation-container ant-col-24">
      <Row justify="space-evenly" align="top">
        <div
          id="panel-reservation-confirmed"
          className="panel ant-col-lg-11 ant-col-md-16 ant-col-sm-22 ant-col-xs-24"
        >
          <CheckCircleOutlined style={{ fontSize: "64px", color: "#c2002f" }} />
          <h3>Your reservation is confirmed!</h3>
          <p>
            Thank you for choosing Royal Air Maroc for your next trip to&nbsp;
            {to}. We've sent an email with your flight confirmation to&nbsp;
            <b>{guess}</b>.
          </p>
        </div>
      </Row>
    </div>
  );
}

export default ReservationForGuess;
